import { useState, useMemo } from "react";
import styles from "./App.module.css";

import PeopleCountRadio from "./components/PeopleCountRadio";
import InputWithSlider from "./components/InputWithSlider";

const config = {
  jaeren: {
    dividendFactor: 0.0033,
    minimumPayment: 100,
    year: 2024,
    disclaimerText: `Utbyttesummen refererer til foreløpig overskuddsdisponering. Formelt
            vedtak fattes av generalforsamlingen 10. april. Kalkulatoren beregner ut
            fra at du har vært kunde hele året. Nedre utbetalingsgrense er 100
            kroner. Dersom du har hatt mindre enn 30 000 kroner i lån og/eller
            innskudd, vil du for 2024 ikke kvalifisere til minimumsutbetalingen på
            100 kroner.`,
  },
  sandnes: {
    dividendFactor: 0.00335,
    minimumPayment: 0,
    year: 2024,
    disclaimerText: `Det oppgitte taller indikerer hva du får utbetalt dersom bankens forstanderskap vedtar styrets
            forslag om kundeutbytte for året 2024. Det blir avgjort 31. mars 2025. Kalkulatoren beregner ut fra at du
            har vært kunde hele året. Har du ikke vært kunde i hele 2024, får du likevel kundeutbytte, men kalkulatoren
            vil da ikke gi deg riktig beløp.`,
    style: {
      "--color-font-secondary": "#555",
      "--color-input-font": "#222",
      "--color-input-background": "#ffd600",
      "--color-slider-background": "#ffd600",
      "--color-slider-thumb": "#222",
      "--color-radio-background": "#ffd600",
      "--color-radio-dot": "#222",
    }
  },
  narvik: {
    dividendFactor: 0.0025,
    minimumPayment: 100,
    year: 2024,
    disclaimerText: `Utbyttesummen refererer til foreløpig overskuddsdisponering. Formelt
            vedtak fattes av bankens generalforsamling i mars og utbetales i april 2025.
            Kalkulatoren beregner ut fra at du har vært kunde hele året. Nedre utbetalingsgrense
            er 100 kroner. Dersom du har hatt mindre enn 40 000 kroner i lån og/eller innskudd,
            vil du i 2024 ikke kvalifisere til minimumsutbetalingen på 100 kroner.`,
    style: {
      "--color-font-secondary": "#555",
      "--color-input-font": "#fff",
      "--color-input-background": "#0065A4",
      "--color-slider-background": "#55BCE7",
      "--color-slider-thumb": "#0065A4",
      "--color-radio-background": "#55BCE7",
      "--color-radio-dot": "#0065A4",
    }
  },
  oslofjord: {
    dividendFactor: 0.0028,
    minimumPayment: 100,
    year: 2024,
    disclaimerText: `Utbyttesummen refererer til foreløpig overskuddsdisponering. Formelt
            vedtak fattes av representantskapet i banken hver vår. Kalkulatoren beregner ut
            fra at du har vært kunde hele året. Nedre utbetalingsgrense er 100 kroner.
            Dersom du har hatt mindre enn 40 000 kroner i lån og/eller innskudd, vil du i
            2024 ikke kvalifisere til minimumsutbetalingen på 100 kroner.`,
    style: {
      "--color-font-secondary": "#555",
      "--color-input-background": "#1B4650",
      "--color-slider-thumb": "#1B4650",
      "--color-radio-dot": "#1B4650",
    }
  },
}

function App() {
  const [loan, setLoan] = useState(2000000);
  const [deposit, setDeposit] = useState(1000000);
  const [peopleCount, setPeopleCount] = useState("1");

  const loanMaxAmount = +peopleCount === 2 ? 4000000 : 2000000;
  const depositMaxAmount = 2000000;

  const windowUrl = window.location.search;
  const params = useMemo(() => new URLSearchParams(windowUrl), [windowUrl]);
  const bank = params.get("bank") || 'jaeren';
  const settings = config[bank];

  function onPeopleCountChange(value) {
    if (value === "1" && formatToInt(loan) > 2000000) {
      setLoan(formatToCurrency(2000000));
    }
    setPeopleCount(value);
  }

  function handleLoanChange(input) {
    if (!input) return setLoan("0");

    const amount = formatToInt(input);
    if (isNaN(amount)) return;

    setLoan(
      amount < 0
        ? "0"
        : amount > loanMaxAmount
        ? formatToCurrency(loanMaxAmount)
        : formatToCurrency(amount)
    );
  }

  function handleDepositChange(input) {
    if (!input) return setDeposit("0");

    const amount = formatToInt(input);
    if (isNaN(amount)) return;

    setDeposit(
      amount < 0
        ? "0"
        : amount > depositMaxAmount
        ? formatToCurrency(depositMaxAmount)
        : formatToCurrency(amount)
    );
  }

  // Convert number to currency string
  function formatToCurrency(value) {
    return Number(value)
      ?.toLocaleString("nb-NO", {
        style: "currency",
        currency: "NOK",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
      .replace("kr", "")
      .trim();
  }

  // Convert currency formatted string to integer
  function formatToInt(input) {
    return typeof input === "number"
      ? input
      : parseInt(input.replace(/[^0-9.-]+/g, ""));
  }

  function getDividend() {
    const factor = settings.dividendFactor;
    const minimumPayment = settings.minimumPayment;

    const dividend = (formatToInt(loan) + formatToInt(deposit)) * factor;
    const result = dividend < minimumPayment ? 0 : dividend;

    return result;
  }

  return (
    <div
      className={styles.container}
      style={settings.style}
    >
      <section className={styles.card}>
        <h2 className={styles.heading}>
          Regn ut hva du får i kundeutbytte
        </h2>
        <p className={styles.description}>
          Kalkulatoren viser sånn cirka hva du får i utbytte, med utgangspunkt i
          gjennomsnittlig lån og innskudd per dag i {settings.year}.
        </p>

        <PeopleCountRadio
          peopleCount={peopleCount}
          onChange={onPeopleCountChange}
        />

        <InputWithSlider
          label={"Lån"}
          subLabel={`inntil ${+peopleCount === 2 ? 4 : 2} mill`}
          max={loanMaxAmount}
          value={formatToInt(loan)}
          onChange={handleLoanChange}
          formatToCurrency={formatToCurrency}
        />

        <InputWithSlider
          label={"Innskudd"}
          subLabel={"inntil 2 mill"}
          max={depositMaxAmount}
          value={formatToInt(deposit)}
          onChange={handleDepositChange}
          formatToCurrency={formatToCurrency}
        />

        <section className={styles.result}>
          <h3 className={styles.header}>Beregnet kundeutbytte blir</h3>
          <h3 className={styles.finalAmount}>
            {formatToCurrency(getDividend())} kr
            <sup className={styles.star}>*</sup>
          </h3>

          <p className={styles.termsNote}>
            * Det oppgitte tallet{" "}
            <strong className={styles.bold}>indikerer</strong> hva du får
            utbetalt. Det kan bli litt mer - og det kan bli litt mindre.{" "}
            {settings.disclaimerText}
          </p>
        </section>
      </section>
    </div>
  );
}

export default App;
